<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="3">
              <!-- <CSelect
                placeholder="Search a company"
                :options="companiesOptions"
                :value.sync="searchData.companyId"
              /> -->
            </CCol>
            <CCol col="12" sm="2">
              <!-- <CSelect
                placeholder="Search a maintain status"
                :options="maintainStatusOptions"
                :value.sync="searchData.maintainStatus"
              /> -->
            </CCol>
            <CCol col="12" sm="2">
              <!-- <CSelect
                placeholder="Search a enable"
                :options="enabledOptions"
                :value.sync="searchData.enabled"
              /> -->
            </CCol>
            <CCol col="12" sm="3">
              <!-- <CInput
                :placeholder="$t('search_a') + $t('device_id')"
                v-model="searchData.devid"
                v-on:keyup.enter="getDevices()"
              /> -->
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getQrCodes()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createQrCodesModal = { data: {}, modal: true }">{{ $t('create_qr_code') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <a slot="id" slot-scope="props" :href="'https://parking.chainmeans.com/maintain/api/v1/device/qrCode/' + props.row.id">
              {{ props.row.id }}
            </a>
            <CBadge slot="valid" slot-scope="props" :color="$_.find(validOptions, option => { return option.value === props.row.valid }).color">
              {{ $_.find(validOptions, option => { return option.value === props.row.valid }).label }}
            </CBadge>
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="boundAt" slot-scope="props">
              {{ props.row.boundAt ? $moment(props.row.boundAt).format('YYYY-MM-DD HH:mm:ss') : '' }}
            </font>
            <font slot="createdAt" slot-scope="props">
              {{ $moment(props.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <!-- <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => parsedDataModal = { data: props.row, modal: true }" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-parsedData' })">{{ $t('parsed_data') }}</CButton>
              </CButtonGroup>
            </div> -->
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateQrCodesModal :data="createQrCodesModal.data" :show="createQrCodesModal.modal" @showUpdate="show => createQrCodesModal.modal = show" v-if="$_.some($store.getters.roles, role => { return role === 'root' || role === 'device-createQrCodes' })" />

    </CCol>
  </CRow>
</template>

<script>
import CreateQrCodesModal from '../device/CreateQrCodesModal'

export default {
  name: 'qrCode',
  components: {
    CreateQrCodesModal
  },
  data () {
    return {
      columns: [ 'id', 'devid', 'valid', 'enabled', 'boundAt', 'createdAt'/*, 'action'*/ ],
      data: [],
      options: {
        headings: {
          id: this.$t('qr_code_id'),
          devid: this.$t('device_id'),
          valid: this.$t('valid'),
          enabled: this.$t('enable'),
          boundAt: this.$t('bound_time'),
          createdAt: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'id', 'devid', 'valid', 'enabled', 'boundAt', 'createdAt' ],
        filterable: [ 'id', 'devid' ]
      },
      maintainStatusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('maintain_status') + '...' },
        { value: 0, label: this.$t('normal'), color: 'success' },
        { value: 1, label: this.$t('repair'), color: 'warning' },
        { value: 2, label: this.$t('scrap'), color: 'danger' }
      ],
      validOptions: [
        { value: 1, label: this.$t('valid'), color: 'success' },
        { value: 0, label: this.$t('invalid'), color: 'danger' }
      ],
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      createQrCodesModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getQrCodes () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getQrCodes', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
