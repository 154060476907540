<template>
  <CModal
    :title="$t('create_qr_code')"
    size="lg"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('qty')"
          :placeholder="$t('qty')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="createData.qty"
          type="number"
          :isValid="valid_createData_qty"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('SN')"
          :placeholder="$t('SN')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="createData.SN"
          :isValid="valid_createData_SN"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('NCC')"
          :placeholder="$t('NCC')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="createData.NCC"
          :isValid="valid_createData_NCC"
        />
      </CCol>
    </CRow>
    <hr v-if="qrCodes.length > 0">
    <CRow v-if="qrCodes.length > 0">
      <CCol col="12" sm="12">
        <div class="border border-dark d-flex justify-content-center align-content-start flex-wrap zoom-30" style="width: 2480px;height: 3508px;background-color: #FFF;margin: 1 auto;" v-for="(data, index) in qrCodes" v-bind:key="index" :id="'img' + index">
          <div class="border border-dark text-center" style="width: 243.8px;height: 324.45px;" v-for="qr in data" v-bind:key="qr.id">
            <vue-qr :text="'https://parking.chainmeans.com/maintain/api/v1/device/qrCode/' + qr.id" :size="233.6" :margin="29.2"></vue-qr><br>
            <span style="font-size: 20px;">SN: {{ createData.SN }}</span><br>
            <img src="/img/ncc.jpg" style="height: 22px;"><span style="font-size: 20px;">{{ createData.NCC }}</span>
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" :disabled="!valid_createQrCodes" @click="createQrCodes()">{{ $t('save') }}</CButton>
    <CButton slot="footer" color="dark" :disabled="qrCodes.length === 0" @click="download()">{{ $t('download') }}</CButton>
    <!-- <div slot="footer-wrapper" v-if="qrCodes.length > 0"></div> -->
  </CModal>
</template>

<style>
  .zoom-30 {
    zoom: 30%;
  }
</style>

<script>
import VueQr from 'vue-qr'
import { toPng } from 'html-to-image'

export default {
  name: 'CreateQrCodesModal',
  components: {
    VueQr
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      createData: { qty: 1, SN: '', NCC: '' },
      qrCodes: []
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.createData = { qty: 1, SN: '', NCC: '' }
        this.qrCodes = []
      }
    }
  },
  computed: {
    valid_createData_qty () {
      return /^[1-9][0-9]*$/.test(this.createData.qty) ? true : false
    },
    valid_createData_SN () {
      return this.createData.SN ? true : false
    },
    valid_createData_NCC () {
      return this.createData.NCC ? true : false
    },
    valid_createQrCodes () {
      return this.valid_createData_qty && this.valid_createData_SN && this.valid_createData_NCC
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    createQrCodes () {
      if (this.valid_createQrCodes) {
        const loader = this.$loading.show()
        this.qrCodes = []
        this.$store.dispatch('createQrCodes', { qty: this.createData.qty }).then(res => {
          this.qrCodes = []
          let tmp = []
          for (const i in res) {
            if (i != 0 && i % 100 === 0) {
              this.qrCodes.push(tmp)
              tmp = []
            }
            tmp.push(res[i])
          }
          this.qrCodes.push(tmp)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    download () {
      const time = this.$moment().format('YYYYMMDDHHmmss')
      for (const i in this.qrCodes) {
        const ctx = document.getElementById('img' + i)
        ctx.classList.remove('zoom-30')
        toPng(ctx, { width: 2480, height: 3508 })
          .then(function (dataUrl) {
            const link = document.createElement('a')
            link.download = `qrCodes_${time}_${i}.png`
            link.href = dataUrl
            link.click()
            ctx.classList.add('zoom-30')
        })
      }
    }
  },
  beforeDestroy: function () {
  },
}
</script>
